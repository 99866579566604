<template>    
    <Imovel/>
</template>

<script>
    import Imovel from '../../components/cadastro/Imovel.vue';

    export default {
        name: 'Imoveis',
        components: { Imovel }  
    }
</script>